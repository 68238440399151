import { useEffect } from 'react';
import { Link } from 'react-router-dom';

function App(props) {
  useEffect(() => {
    props.setSiteClass('Home');
  }, []);
  return (
    <>
      <Link to="/work">Professional experience</Link>
      <Link to="/projects">Personal projects</Link>
      <Link to="/resume">Resume</Link>
    </>
  );
}

// Professional work
// Education
// Classic resume
// Hobby projects
// About me

export default App;
