import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import CircularLinkImage from '../components/CircularLinkImage';

function PersonalProjects(props) {
  useEffect(() => {
    props.setSiteClass('PersonalProjects');
  }, []);

  return (
    <>
      <div class="Info">
        Below is a small selection of personal projects that are still viewable
        online.
      </div>
      <div id="PersonalProjects" className="Projects">
        <div className="Project-details">
          <h2>AIs for Dominion Using Monte-Carlo Tree Search</h2>
          <h3>Master thesis</h3>
          <a href="https://uia.brage.unit.no/uia-xmlui/bitstream/handle/11250/221382/IKT-590-G%20-%202014%20-%20Spring%20-%20Master%27s%20thesis%20-%20Jon%20Vegard%20Jansen%20and%20Robin%20Tollisen.pdf?sequence=1&isAllowed=y">
            Downlaod from UiA (direct download link)
          </a>
          <a href="https://link.springer.com/chapter/10.1007/978-3-319-19066-2_5">
            View in publication
          </a>
          <div>
            Master thesis where we created an AI for the card game Dominion
            using C++. Presented at the International Conference on Industrial,
            Engineering and Other Applications of Applied Intelligent Systems in
            Seoul, Korea.
          </div>
        </div>
        <div className="Project-details">
          <h2>Auto Chess Dungeon</h2>
          <h3>Mobile game made in Unity</h3>
          <a href="https://play.google.com/store/apps/details?id=net.Tollisen.AutoDungeon">
            View on Google Play
          </a>
          <div>
            Game created in Unity using C#. Simple auto chess gameplay against
            AI opponents traversing a dungeon.
          </div>
        </div>
        <div className="Project-details">
          <h2>A Lost Toy Story</h2>
          <h3>Global Game Jam 2021 Entry</h3>
          <a href="https://globalgamejam.org/2021/games/lost-toy-story-0">
            View on Global Game Jam site
          </a>
          <div>
            Game created in Unity using C#. Created with two friends over the
            course of 2 days as an entry to the Global Game Jam 2021. Simple
            physics based 3d platformer.
          </div>
        </div>
      </div>
    </>
  );
}

// Professional work
// Education
// Classic resume
// Hobby projects
// About me

export default PersonalProjects;
