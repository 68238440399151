import { useEffect } from 'react';

function Resume(props) {
  useEffect(() => {
    props.setSiteClass('Resume');
  }, []);
  return (
    <>
      <div className="left">
        <h1>Robin Tollisen</h1>
        <h2>Software Engineer</h2>
        <h3>(+47)92885259</h3>
        <h3>robin@tollisen.net</h3>

        <h4>Languages</h4>
        <div>Norwegian - Native</div>
        <div>English - Fluent</div>

        <h4>Technical Skills</h4>
        <div>Javascript</div>
        <ul>
          <li>React</li>
          <li>Vue</li>
          <li>Node.js</li>
        </ul>
        <div>Elixir</div>
        <ul>
          <li>Phoenix Framework</li>
        </ul>
        <div>HTML and CSS</div>
        <div>C# (for Unity)</div>
        <div>Varnish</div>
      </div>
      <div className="right">
        <h1>Professional Experience</h1>
        <div className="Workplace-details">
          <h2>Buypass (2014-2017)</h2>
          <h3>Software Engineer</h3>
          <div>
            Working at Buypass I was a member of a team of consultants for Norsk
            Tipping, who created and maintained their web and tablet websites.
            Initially this was made using jquery for the frontend, java for the
            backend. The tablet application used knockout. I participated in an
            effort to recreate the solution from scratch consolidating all
            (desktop, mobile and tablet) sites into a single react application.
          </div>
        </div>
        <div className="Workplace-details">
          <h2>Schibsted (2017-current)</h2>
          <h3>Software Developer</h3>
          <div>
            Working at Schibsted I worked full stack on a variety of projects.
          </div>
          <ul>
            <li>
              Core News Product - The main news products being migrated to
              React.
            </li>
            <li>
              Privacy Settings Notifier - A service responsible for displaying
              privacy popups on all Schibsted sites in relation to GDPR.
            </li>
            <li>
              Folk - Service where customers can submit birthdays and events for
              print in Schibsted newspapers.
            </li>
            <li>
              Dokumentbase - App responsible for automatically indexing
              documents from courts, and making them searchable using aws
              elastic search.
            </li>
            <li>
              PolarBear Core - As Fvn became a part of Polaris Media we had to
              migrate Fvn to a new news platform created in Vue.
            </li>
          </ul>
        </div>
        <h1>Education</h1>
        <div className="Education-details">
          <h2>Master of Technology (MTech) Computer Science (2009-2014)</h2>
          <div>
            Completed my masters degree in Computer Science at UiA. My master
            thesis was "AIs for Dominion Using Monte-Carlo Tree Search", which
            was presented at the International Conference on Industrial,
            Engineering and Other Applications of Applied Intelligent Systems in
            Seoul, Korea.
          </div>
        </div>
      </div>
    </>
  );
}

export default Resume;
