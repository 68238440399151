import { useEffect } from 'react';

function Work(props) {
  useEffect(() => {
    props.setSiteClass('Work');
  }, []);

  return (
    <>
      <div id="Work" className="Workplaces">
        <div className="Workplace-details">
          <h2>Buypass</h2>
          <h3>Software Engineer</h3>
          <div>
            Working at Buypass I was a member of a team of consultants for Norsk
            Tipping, who created and maintained their web and tablet websites.
            Initially this was made using jquery for the frontend, java for the
            backend. The tablet application used knockout. I participated in an
            effort to recreate the solution from scratch consolidating all
            (desktop, mobile and tablet) sites into a single react application.
          </div>
        </div>
        <div className="Workplace-details">
          <h2>Schibsted</h2>
          <h3>Software Developer</h3>
          <div>
            Working at Schibsted I worked full stack on a variety of projects.
          </div>
          <ul>
            <li>
              Core News Product - The main news products being migrated to
              React.
            </li>
            <li>
              Privacy Settings Notifier - A service responsible for displaying
              privacy popups on all Schibsted sites in relation to GDPR.
            </li>
            <li>
              Folk - Service where customers can submit birthdays and events for
              print in Schibsted newspapers.
            </li>
            <li>
              Dokumentbase - App responsible for automatically indexing
              documents from courts, and making them searchable using aws
              elastic search.
            </li>
            <li>
              PolarBear Core - As Fvn became a part of Polaris Media we had to
              migrate Fvn to a new news platform created in Vue.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

// Professional work
// Education
// Classic resume
// Hobby projects
// About me

export default Work;
