import { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Home from './screens/Home';
import Work from './screens/Work';
import Resume from './screens/Resume';
import PersonalProjects from './screens/PersonalProjects';
import './App.scss';

export default function BasicExample() {
  const [siteClass, setSiteClass] = useState('Home');

  return (
    <div className={`App ${siteClass}`}>
      <Router>
        <header className="App-header">
          <Link to="/">
            <h1>Robin Tollisen</h1>
          </Link>
        </header>
        <main className="App-main">
          <Switch>
            <Route exact path="/">
              <Home setSiteClass={setSiteClass} />
            </Route>
            <Route path="/work">
              <Work setSiteClass={setSiteClass} />
            </Route>
            <Route path="/projects">
              <PersonalProjects setSiteClass={setSiteClass} />
            </Route>
            <Route path="/resume">
              <Resume setSiteClass={setSiteClass} />
            </Route>
          </Switch>
        </main>
      </Router>
    </div>
  );
}
